body{
  font-size: 0.9rem;


  font-weight: 400;
}

.form-control
{
  font-size: 0.9rem;
}

.blink_me {
  animation: blinker 1s linear infinite;
  opacity:1;
}

.waitingForConnection {
  animation: blinker 1.7s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}
@keyframes blinker { 50% { opacity: 0; } }
/*@font-face {*/
/*  font-family: 'Roboto';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: local('Roboto'), local('Roboto-Regular'), url(./assets/fonts/roboto.ttf) format('truetype');*/
/*}*/

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


.freetext-search {

}

.header-navbar
{
  background-image:url(assets/images/menu2.jpg);
  background-size:contain;
}
.header-navbar.debugMode{
  background: #999;
  box-shadow: 0px 2px 14px #dca95b;
}

div.rt-thead.-filters > .rt-tr > .rt-th
{
  overflow:visible;
}

label
{
  color:gray;
  font-size:0.9em;
  text-decoration: underline;
}
a{
  color:#547ca7;
}

.subpanel-button
{
  margin-top:10px;
}
.primary-button{
  background-color: #efd375;
  color: #382c05;
}
.primary-button:hover{
  background-color: #eeca54;
}
.primary-button-outline{
  background-color: white;
  color: #d4a603;
}
.primary-button-outline:hover{
  background-color: #eeca54;
  color: white;
}
.body{
  display: flex;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
}

.sidebar-nav {
  position: relative;
  background-color: #373d47;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-shrink:0;
  width: 0;
  transition: width 0.3s;
  height: 100%;
}
.sidebar-nav.expand{
  width: 200px;
}
.sidebar-nav .toggleButton{
  position: absolute;
  right: -20px;
  top: 45%;
  width:20px;
  height: 10%;
  background-color: #373d47;
  transition: right 0.3s;
  border-radius: 0 5px 5px 0;
  z-index: 1;
}
.sidebar-nav.expand .toggleButton{
  right: 0;
}
.sidebar-nav .toggleIcon{
  display: block;
  position: absolute;
  top: calc(50% - 10px);
  right: 0;
  width:20px;
  height: 20px;
  transition: transform 0.3s;
  transform-origin: center;
  transform: rotate(0deg);
}
.sidebar-nav.expand .toggleIcon{
  transform: rotate(-180deg);
}
.sidebar-nav li > a {

  color: #cfcfcf;
}

.sidebar-nav li > a:hover {
  color: #666b71;
  background-color: #cfcfcf;
}
.sidebar-nav .nav-link.active
{
  color: #666b71;
  background-color: #cfcfcf;
}
.page-wrapper {
  position: relative;
  padding: 0;
  flex: 1;
  overflow: auto;
  height: 100%;
}

.header-navbar
{
  padding:10px 0 0 10px;
}

.header-navbar .nav-item .nav-link
{
  color:white;
  margin-bottom:10px;
}
.header-navbar .dropdown > a
{
  color:white;
  margin-bottom:10px;
}
.header-navbar .nav-item .nav-link.active
{
  color:gray;
  background-color: ghostwhite;

}

.big-checkbox
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */

}

.link
{
  color: #547ca7;
  cursor:pointer;
  text-decoration: none;
}

.link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.highlight:not(.djs-connection) .djs-visual > :nth-child(1) {
  stroke-width: 2px !important;
  stroke: #155cb5 !important;
  fill:rgba(194,213,237,0.4) !important
}

.sorting-row
{
  padding: 8px;
  line-height: 1.428571429;
  vertical-align: top;
  border-top: 1px solid #ddd;
  background-color:lightblue;
}
/* This is for drag and drop to show up correctly.*/
body > tr
{
  display: table !important;
}

.bpmn-diagram  svg
{
  border:dotted 1px;
}

.fc-event
{
  border-width:2px !important;
}

.Select--multi .Select-multi-value-wrapper > .Select-value
{
  display:block;
  font-size:0.5em;
  line-height:0.5;
}
.rt-th .Select--multi.has-value .Select-input
{
  display:none !important;
}
.Select-multi-value-wrapper
{
  white-space: normal;
  /*overflow-x: scroll;*/
  /*width: 250px;*/
}

.ReactTable .rt-tbody .rt-td
{
  font-size:1.2em;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  font-size:1.2em;
  font-weight:bold;
}

.ReactTable.-thin .rt-td{
  padding-top: 0;
  padding-bottom: 0;
}
.ReactTable.-thin .button, .ReactTable.-thin button{
  padding: 0 7px;
}

.form-control.form-check-input
{
  position:relative;
}
.drawingBuffer {
  position: absolute;
  top: 0;
  left: 0;
}
.article-rows-spinner
{
  position:fixed;
  font-size:40px;
  top:50%;
  left:50%;
}

.article-rows-spinner .spinner-border
{
  height:3em;
  width:3em;
}

.validation-list
{
  padding-left:0;
  list-style: none;
}

.validation-list>li
{
  color:red;
  border-bottom:1px dashed black;
  font-size:14px;
}

.tooltip.show{
  opacity: 1;
}


#calendar .fc-head .fc-time-area tr:nth-child(2) th:nth-child(odd){
  background-color: #ccc;
}

#calendar .fc-timeline30Days-view .fc-head .fc-time-area tr:nth-child(3) .fc-cell-text{
  padding-left: 0px;
  padding-right: 0px;
  writing-mode: vertical-rl;
  line-height: 0px;
}

.powderCoatingTable{
  width: 100%;
  margin-bottom: 4px;
}
.powderCoatingTable td, .powderCoatingTable th{
  padding: 3px 5px 3px 12px;
}

.powderCoatingTable tr:hover td{
  background-color: #e4e4e4;
}

.powderCoatingTable td.plating, .powderCoatingTable th.plating{
  background-color: #e3ebf3;
}
.powderCoatingTable tr:hover td.plating{
  background-color: #c8d7e7;
}

.powderCoatingTable td.powderCoating, .powderCoatingTable th.powderCoating{
  background-color: #fff7e8;
}
.powderCoatingTable tr:hover td.powderCoating{
  background-color: #f1e2c8;
}

.powderCoatingTable .sum{
  font-weight: bold;
  color: #720c0c;
}

.inventoryNavBar {
  background-color: #547ca7;
  width: 100%;
  color: white;
  box-shadow: 0 -3px 15px black;
  margin-bottom: 8px;
}

.inventoryNavBar .nav-link span,
.inventoryNavBar .nav-link a {
  color: white;
}

.inventoryNavBar .nav-link a:hover {
  color: white;
}

.modulePanel {
    height: 100%;
    width: 106px;
    min-width: 106px;
}
.modulePanel .group{
    border-radius: 5px;
    border: 1px solid #666;
    margin-bottom: 5px;
    overflow: hidden;
}

.modulePanel .group .label{
    margin-top: -1px;
    background-color: #373d47;
    color:white;
    width: 100%;
    display: block;
    text-align: center;
}

.modulePanel .button {
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.modulePanel .button .icon{
    width: 20px;
    height: fit-content;
    margin: 2px;
}

.modulePanel .button:hover {
    background-color: #eee;
}

.modulePanel .button.selected {
    background-color: #ffeeba;
}

.controlBar{
    padding: 10px 0;
}

.controlBar .space{
    width: 20px;
    display: inline-block;
}

.moduleSettingBar {
    width: 100%;
    height: 42px;
    background: #ccc;
    padding: 2px;
}
.moduleSettingBar.hide{
    visibility: hidden;
    opacity: 0;
}
.moduleSettingBar > div{
    display: inline-block;
}
.moduleSettingBar .inputField{
    display: inline-flex;
    align-items: center;
    width: 120px;
    margin-right: 10px;
}

.moduleSettingBar .inputField > label{
    margin: 0 5px;
    text-decoration: none;
    text-transform: capitalize;
    color: #666;
    font-size: 16px;
    white-space: nowrap;
}

.moduleSettingBar .inputField > div{
    margin: 0 5px;
    width: 200px;
}

.moduleSettingBar .inputField input{
    padding: 5px;
}
.moduleSettingBar .form-check-input {
    position: relative;
}

 .previewImage {
    display: none;
    position: absolute;
    background: white;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.infoBox {
    border: 1px solid black;

}

.infoBox .block{
    border-bottom: 1px solid black;
}

.infoBox .title{
    border-bottom: 1px solid black;
    padding: 0 3px;
}

.infoBox .infoRow{
    width: 200px;
    border-bottom: 1px solid #aaa;
}

.infoBox .block .key{
    border-right: 1px solid #666;
    font-weight: bold;
    background: #eee;
    min-width: 70px;
    width: fit-content;
    padding: 0 3px;
}

.infoBox .block .value{
    flex: 1;
    padding: 0 3px;
}

.infoBox .textInput{
    padding: 0;
    height: unset;
    width: 80px;
    border-radius: 0;

}

.canvasWrapper{
    position: relative;
}

.newModuleCanvas{
    position: absolute;
    display: none;
    right: 0;
    top: 0;
    background-color: #373d47;
    z-index: 1;
}

.newModuleCanvas.show{
    display: block;
}

.container{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 600px;
}

.centerBlock {
    position: relative;
    width: 50px;
    height: 100px;
}
.animateStair {
    position: absolute;
    top:0;
    width: 50px;
}

.animateStair.outer{
    animation: outer 1.6s ease-in-out infinite alternate;
}

.animateStair .inner{
    animation: inner 0.8s ease-in-out infinite alternate;
    height: 10px;
    border-radius: 10px;
    background-color: #f1bf25;
    margin: 0 auto;
}

.stair1.outer{ top:20px; animation-delay: -0.0s; }
.stair2.outer{ top:40px; animation-delay: -0.3s; }
.stair3.outer{ top:60px; animation-delay: -0.6s; }
.stair4.outer{ top:80px; animation-delay: -0.9s; }

.stair1 .inner{ animation-delay: -0.8s; }
.stair2 .inner{ animation-delay: -1.1s; }
.stair3 .inner{ animation-delay: -1.4s; }
.stair4 .inner{ animation-delay: -1.7s; }

@keyframes outer {
    0%   {left:30px;}
    100% {left:-30px;}
}

@keyframes inner {
    0%   {width: 20px;}
    100% {width: 50px;}
}
.price-list .hidden{
    display: none;
}

.price-list .row{
    position: relative;
    width:100%;
    display:flex;
}
.price-list .row label{
    width:80px;
    margin: 10px 0 10px 20px;
}
.price-list .row input,.price-list .row span{
    width:20%;
    margin: 7px;
    flex: 1;
}
.price-list .row input.changed{
    background: #f8dcb1;
}
.price-list .row .myAlert{
    position: absolute;
    right:20px;
    top:10px;
    width: fit-content;
    height: 10px;
    pointer-events: none;
    color: #ff0000;
}
.price-list fieldset
{
    border: 2px solid #eee;
    border-top: 5px solid #DE9A03;
    padding: 10px;
    margin: 25px auto;
    width:500px;
    min-width: fit-content;
}
.price-list legend
{
    width:fit-content;
    padding: 0 5px;
    cursor: default;
}
.price-list .infoIcon{
    position: relative;
    color:gray;
    cursor: default;
}
.price-list .info{
    position: absolute;
    top:-8px;
    left:35px;
    width: 300px;
    height: fit-content;
    pointer-events: none;
    background-color: white;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 5px;
    color:black;
    z-index: 10;

}
.price-list .info::before{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left:-14px;
    top:5px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;

    border-right:12px solid #ddd;
}
.price-list .fixedButtonBox{
    position: fixed;
    bottom: 0;
    right:0;
    padding: 20px 30px;
    background-color: #edb438;
    text-align: center;
}

.price-list .centerButtonBox{
    width: 100%;
    margin-top: 50px;
    padding: 10px 10px;
    background-color: #edb438;
    text-align: center;
    height: 70px;
}
